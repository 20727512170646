export const SET_QH_DATA_LIST = "SET_QH_DATA_LIST";
export const SET_QH_DATA_LIST_FILTER = "SET_QH_DATA_LIST_FILTER";
export const SET_ACTIVE_PUMP_FAMILY = "SET_ACTIVE_PUMP_FAMILY";
export const SET_PUMPFAMILY_LIST_ASYNC = "SET_PUMPFAMILY_LIST_ASYNC";
export const SET_IMPELLER_LIST_ASYNC = "SET_IMPELLER_LIST_ASYNC";
export const SET_IMPELLER_MATERIAL_LIST_ASYNC = "SET_IMPELLER_MATERIAL_LIST_ASYNC";
export const GET_PUMP_FAMILY_LIST_PENDING = " GET_PUMP_FAMILY_LIST_PENDING";
export const SET_PUMP_FAMILY_LIST = "SET_PUMP_FAMILY_LIST";
export const SET_PUMP_SUBFAMILY_LIST_ASYNC = "SET_PUMP_SUBFAMILY_LIST_ASYNC";
export const SET_ACTIVE_PUMP_SUBTYPE = "SET_ACTIVE_PUMP_SUBTYPE";
export const SET_PUMP_FAMILY_LIST_PENDING = "SET_PUMP_FAMILY_LIST_PENDING";
export const CLEAR_PUMP_DATA_LIST_FILTER = "CLEAR_PUMP_DATA_LIST_FILTER";
export const SEARCH_DATA_ERROR = "SEARCH_DATA_ERROR";
export const SET_FLOWMETER_LIST_ASYNC = "SET_FLOWMETER_LIST_ASYNC";
export const SET_MODIFIED_DATA = "SET_MODIFIED_DATA";
export const SET_PUMP_DATA_LIST_FILTER = "SET_PUMP_DATA_LIST_FILTER";
export const GET_SEARCH_DATA_PENDING = "GET_SEARCH_DATA_PENDING";
export const SET_SEARCH_PUMP_DATA = "SET_SEARCH_PUMP_DATA";
export const GET_SEARCH_DATA_SUCCESS = "GET_SEARCH_DATA_SUCCESS";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const SET_UNIT_VALUE_SELECTION = "SET_UNIT_VALUE_SELECTION";
export const SET_GRAPH_DATA = "SET_GRAPH_DATA";
export const SET_PRINT_DATA = "SET_PRINT_DATA";
export const GET_GRAPH_DATA_SUCCESS = "GET_GRAPH_DATA_SUCCESS";
export const SET_QH_SELECTION_FILTERED_PUMP_DATA_IDS = "SET_QH_SELECTION_FILTERED_PUMP_DATA_IDS";
export const GET_PRINT_DATA_SUCCESS = "GET_PRINT_DATA_SUCCESS";
export const UPDATE_GRAPH_DATA_SUCCESS = "UPDATE_GRAPH_DATA_SUCCESS";
export const UPDATE_GRAPH_DATA_ERROR = "UPDATE_GRAPH_DATA_ERROR";
export const UPDATE_GRAPH_DATA_PENDING = "UPDATE_GRAPH_DATA_PENDING";
export const SET_CURRENT_PUMP_DETAILS = "SET_CURRENT_PUMP_DETAILS";
export const SET_CURRENT_FIELD_CHANGE = "SET_CURRENT_FIELD_CHANGE";

export const DENSITY_LIST = [
  {
    label: "Density",
    value: "Density",
    index: "0",
  },
  {
    label: "S.G",
    value: "S.G",
    index: "1",
  },
];

export const DEN_LIST = [
  "Density",
  "S.G",
];

export const VIS_UNITS_US = [
  {
    label: "ft2/hr",
    value: "ft2/hr",
    index: "0",
  },
  {
    label: "lb/ft*hr",
    value: "lb/ft*hr",
    index: "1",
  },
  {
    label: "mm2/s[cSt]",
    value: "mm2/s[cSt]",
    index: "2",
  },
  {
    label: "mPas[cP]",
    value: "mPas[cP]",
    index: "3",
  },
];
export const VIS_UNITS_METRIC = [
  {
    label: "mm2/s[cSt]",
    value: "mm2/s[cSt]",
    index: "0",
  },
  {
    label: "mPas[cP]",
    value: "mPas[cP]",
    index: "1",
  },
];
export const FREQ_LIST = [
  {
    label: "60",
    value: "60",
  },
  {
    label: "50",
    value: "50",
  },
];

export const SPEED_LIST_50 = [
  {
    label: "750",
    value: "750",
  },
  {
    label: "1000",
    value: "1000",
  },
  {
    label: "1500",
    value: "1500",
  },
  {
    label: "3000",
    value: "3000",
  },
];

export const SPEED_LIST_60 = [
  {
    label: "900",
    value: "900",
  },
  {
    label: "1200",
    value: "1200",
  },
  {
    label: "1800",
    value: "1800",
  },
  {
    label: "3600",
    value: "3600",
  },
];
export const REGION_LIST = [
  {
    label: "Standard",
    value: "standard",
  },
  {
    label: "API 610",
    value: "api610",
  },
  {
    label: "User",
    value: "user",
  },
];

export const PUMP_DATA_SEARCH_OPTIONS = [
  {
    label: "Capacity",
    value: "capacity",
  },
  {
    label: "Head",
    value: "Head",
  },
  {
    label: "Viscosity",
    value: "Viscosity",
  },
  {
    label: "Density",
    value: "SoortMassa",
  },
  {
    label: "Frequency",
    value: "Frequency",
  },
  {
    label: "Speed",
    value: "Speed",
  },
  {
    label: "NPSHa",
    value: "NPSHa",
  },
  {
    label: "Efficiency",
    value: "Eff",
  },
  {
    label: "Oper Region",
    value: "selected_region_filter",
  },
  {
    label: "regionmin",
    value: "LowOperRegion",
  },
  {
    label: "regionmax",
    value: "HighOperRegion",
  },
  {
    label: "Tolerance",
    value: "Tolerance",
  },
  {
    label: "CS Bearings",
    value: "Bearings",
  },
  {
    label: "Family",
    value: "",
  },
  {
    label: "Sub Type",
    value: "",
  },
  {
    label: "Impeller Type",
    value: "",
  },
  {
    label: "Material",
    value: "",
  },
];

export const DEFAULT_SEARCH_DATA = {
  capacity: {
    data: "",
    type: "input",
  },
  head: {
    data: "",
    type: "input",
  },
  viscosity: {
    data: "",
    type: "input",
  },
  Frequency: {
    data: "",
    type: "input",
  },
  Speed: {
    data: "",
    type: "input",
  },
  NPSHa: {
    data: "",
    type: "input",
  },
  Efficiency: {
    data: "",
    type: "input",
  },
  LowOperRegion: {
    data: "",
    type: "input",
  },
  HighOperRegion: {
    data: "",
    type: "input",
  },
};

export const PUMP_DATA_SORTING_OPTIONS = [
  {
    label: "Efficiency: Low to High",
    value: "Eff#asc",
  },
  {
    label: "Efficiency: High to Low",
    value: "Eff#desc",
  },
  {
    label: "Pump Size: Low to High",
    value: "type#asc",
  },
  {
    label: "Pump Size: High to Low",
    value: "type#desc",
  },
  {
    label: "Impeller : Low to High",
    value: "waaierType#asc",
  },
  {
    label: "Impeller: High to Low",
    value: "waaierType#desc",
  },
  {
    label: "NPSHA : Low to High",
    value: "npshr#asc",
  },
  {
    label: "NPSHA: High to Low",
    value: "npshr#desc",
  },
  {
    label: "Speed: Low to High",
    value: "rpm#asc",
  },
  {
    label: "Speed: High to Low",
    value: "rpm#desc",
  },
];

export const EFF_CURVE = [
  {
    label:"Curve 1",
    value:"curve1",
  },
  {
    label:"Curve 2",
    value:"curve2",
  },
  {
    label:"Curve 3",
    value:"curve3",
  },
  {
    label:"Curve 4",
    value:"curve4",
  },
];

export const NPSH_CURVE = [
  {
    label:"Curve 1",
    value:"curve1",
  },
  {
    label:"Curve 2",
    value:"curve2",
  },
  {
    label:"Curve 3",
    value:"curve3",
  },
  {
    label:"Curve 4",
    value:"curve4",
  },
];