import {
  CLEAR_IMPORT_DATA_ERROR,
  IMPORT_DATA_ERROR,
  IMPORT_DATA_PENDING,
  IMPORT_DATA_SUCCESS,
  SET_INITIAL_STATE,
} from "../constants/import.constants";

const initialState = {
  isPending: false,
  errorMessage: null
};

const importReducer = (
  localState: Object = initialState,
  action: any
): Object => {
  switch (action.type) {
    case SET_INITIAL_STATE: {
      return {
        ...initialState
      };
    }
    case IMPORT_DATA_SUCCESS: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case IMPORT_DATA_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case IMPORT_DATA_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case CLEAR_IMPORT_DATA_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    default:
      return localState;
  }
};

export default importReducer;
