import { qhDataState } from "../../types/qhData";
import { GET_SEARCH_DATA_ERROR } from "../constants/pumpData.constants";
import {
  SET_QH_DATA_LIST_FILTER,
  SET_ACTIVE_PUMP_FAMILY,
  CLEAR_PUMP_DATA_LIST_FILTER,
  SET_MODIFIED_DATA,
  SET_PUMP_DATA_LIST_FILTER,
  SET_SEARCH_PUMP_DATA,
  SET_INITIAL_STATE,
  SET_UNIT_VALUE_SELECTION,
  SEARCH_DATA_ERROR,
  GET_GRAPH_DATA_SUCCESS,
  SET_GRAPH_DATA,
  SET_PRINT_DATA,
  GET_PRINT_DATA_SUCCESS,
  SET_QH_SELECTION_FILTERED_PUMP_DATA_IDS,
  GET_SEARCH_DATA_PENDING,
  UPDATE_GRAPH_DATA_PENDING,
  UPDATE_GRAPH_DATA_SUCCESS,
  UPDATE_GRAPH_DATA_ERROR,
  SET_CURRENT_PUMP_DETAILS,
  SET_CURRENT_FIELD_CHANGE
} from "../constants/qh-selection.constants";

const initialState: qhDataState = {
  selectedUnit: 'metric',
  filter: {
    selected_viscosity_filter: "ft2/hr",
    selected_sorting: {
      label: "Efficiency: Low to High",
      value: "Eff#asc",
    },
    family: [{ label: 'All', value: 'All' }],
    impeller: [{ label: 'All', value: 'All' }],
    impMaterial: [{ label: 'All', value: 'All' }],
    subType: [{ label: 'All', value: 'All' }],
  },
  pumpDataList: [],
  graphData: {},
  filteredPumpDataIds: [],
  filteredPumpIndexes: [],
  isPending: false,
  currentPumpDetails: {},
  currentFieldChange: ""
};

export default (
  localState: UserState = initialState,
  action: Action
): qhDataState => {
  switch (action.type) {
    case SET_INITIAL_STATE: {
      return {
        ...initialState,
        isTestSelectionModalOpen: true,
        newValue: {
          ...action.payload,
        }
      };
    }
    case SET_QH_DATA_LIST_FILTER: {
      return {
        ...localState,
        filter: {
          ...action.payload,
        },
      };
    }
    case CLEAR_PUMP_DATA_LIST_FILTER: {
      return {
        ...localState,
        filter: {
          ...initialState.filter,
        },
      };
    }
    case SET_MODIFIED_DATA: {
      return {
        ...localState,
        isDataModified: true,
      };
    }
    case SET_PUMP_DATA_LIST_FILTER: {
      return {
        ...localState,
        filter: {
          ...action.payload,
        },
      };
    }
    case SET_ACTIVE_PUMP_FAMILY: {
      return {
        ...localState,
        activePumpFamily: action.payload,
      };
    }
    case SET_SEARCH_PUMP_DATA: {
      const { pumpListOdata } = action.payload;
      return {
        ...localState,
        isPending: false,
        pumpDataList: pumpListOdata && pumpListOdata.value,
        totalRecords: pumpListOdata["@odata.count"],
      };
    }
    case SET_GRAPH_DATA: {
      return {
        ...localState,
        graphData: action.payload,
      };
    }
    case SET_PRINT_DATA: {
      return {
        ...localState,
        printData: action.payload,
      };
    }
    case GET_GRAPH_DATA_SUCCESS: {
      return {
        ...localState,
      };
    }
    case GET_PRINT_DATA_SUCCESS: {
      return {
        ...localState,
      };
    }
    case SET_UNIT_VALUE_SELECTION: {
      console.log(action.payload)
      return {
        ...localState,
        selectedUnit: action.payload,
      };
    }
    case SEARCH_DATA_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case SET_QH_SELECTION_FILTERED_PUMP_DATA_IDS: {
      const { pumpDataIds, pumpIndexes } = action.payload;
      return {
        ...localState,
        filteredPumpDataIds: pumpDataIds,
        filteredPumpIndexes: pumpIndexes
      };
    }
    case GET_SEARCH_DATA_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case GET_SEARCH_DATA_ERROR: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case UPDATE_GRAPH_DATA_PENDING: {
      return {
        ...localState,
        isGraphPending: true,
      };
    }
    case UPDATE_GRAPH_DATA_SUCCESS: {
      return {
        ...localState,
        isGraphPending: false,
      };
    }
    case UPDATE_GRAPH_DATA_ERROR: {
      return {
        ...localState,
        isGraphPending: false,
      };
    }
    case SET_CURRENT_PUMP_DETAILS: {
      return {
        ...localState,
        currentPumpDetails: action.payload
      }
    }
    case SET_CURRENT_FIELD_CHANGE: {
      return {
        ...localState,
        currentFieldChange: action.payload
      }
    }
    default: {
      return localState;
    }
  }
};
