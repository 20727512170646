import moment from "moment";
import { logoutUser } from "../store/actions/login.actions";

export const getToken = (dispatch?: Function): any => {
  const access_token = sessionStorage.getItem("token");
  const expires_in = sessionStorage.getItem("expires_in");

  return new Promise((resolve) => {
    // check the difference between now and expired time for current token
    const diff = moment.unix(Number(expires_in)).diff(moment(), "minutes");

    if (access_token && diff > 5) {
      resolve(access_token);
    } else {
      console.log("getToken ", diff);
      dispatch && dispatch(logoutUser("TokenExpired"));
      resolve(access_token);
    }
  });
};

export const modifyRolesList = (roles: any) => {
  const roleItems = [];
  roles.forEach((role) => {
    const { roleDisplayName, id } = role;
    roleItems.push({
      label: roleDisplayName,
      value: id,
    });
  });
  return roleItems;
};

export const modifyFlowMetersList = (flowMeters: any) => {
  const flowMeterItems = [];
  flowMeters.forEach((flowMeter) => {
    const { flowMeterNo, flowMeterMake } = flowMeter;
    flowMeterItems.push({
      ...flowMeter,
      label: `${flowMeterNo} | ${flowMeterMake}`,
      value: flowMeterNo,
    });
  });
  return flowMeterItems;
};

export const calculateVapour = (waterTemp: any) => {
  if (isNaN(parseFloat(waterTemp))) return 0;
  return checkDecimalNumber({
    value:
      waterTemp *
        (waterTemp * (waterTemp * 5.7686e-6 + 6.7078e-5) + 5.2133e-3) +
      6.0899e-2,
    decimalPosition: 6,
  });
};

export const calculatedEff = ({ mPower, mVoltage, mCurrent, mCosPhi }) => {
  if (
    isNaN(parseFloat(mPower)) ||
    isNaN(parseFloat(mVoltage)) ||
    isNaN(parseFloat(mCurrent)) ||
    isNaN(parseFloat(mCosPhi))
  )
    return 0; 
  return checkDecimalNumber({
    value: ((mPower * 1000) / (mVoltage * mCurrent * 1.732 * mCosPhi)) * 100,
    decimalPosition: 2,
  });
};

export const getPumpDataIds = (pumpOdataDataList: any) => {
  const pumpDataList = pumpOdataDataList?.value ?? [];
  return pumpDataList.map(function (pumpData) {
    return pumpData["id"];
  });
};

export const GetGraphRequestData = (
  generalData: any,
  testData: any,
  NPSHData: any,
  graphFormData: any
) => {
  let testCurveId,
    sTDCurveIn,
    toleranceId = null,
    toleranceEnabled = false,
    secondCurveType = null,
    magCor = null;

  if (graphFormData) {
    const { graphParameters, localfields, standardCurve } = graphFormData;
        
    if (graphParameters) {
      toleranceEnabled = graphParameters.toleranceEnabled;
      toleranceId = graphParameters.toleranceId;
      magCor = graphParameters.magCor;
    }    

    if (localfields) {
      secondCurveType = localfields.secondCurveType;
      testCurveId = localfields.testCurve && localfields.testCurve.testId;
      sTDCurveIn = standardCurve;
    }
  }

  return {
    generalPumpData: generalData,
    inputTestData: testData,
    inputNPSHData: NPSHData,
    graphInputData: {
      testCurveId,
      sTDCurveIn,
      toleranceId,
      toleranceEnabled,
      secondCurveType,
      magCor,
    },
  };
};

export const modifyDutyPoints = (dutyPoints: any, showDutyPoints: any) => {
  let result = {};
  if (!dutyPoints) return result;
  Object.entries(dutyPoints).forEach((obj) => {
    const [key, value] = obj;
    if (Array.isArray(value)) {
      showDutyPoints.forEach((show, i) => {
        value[i].show = show;
      });
    }
    result = {
      ...result,
      [key]: value,
    };
  });
  return result;
};

export const modifyTolerancePoints = (tolerance: any, showDutyPoints: any) => {
  let result = tolerance;
  if (!result) return result;
  if (tolerance.drawtype === "Ellipse") {
    Object.entries(tolerance.ellipseTolerance).forEach((obj) => {
      const [key, value] = obj;
      if (Array.isArray(value)) {
        showDutyPoints.forEach((show, i) => {
          value[i].show = show;
        });
      }
      result = {
        ...result,
        ellipseTolerance: {
          ...result.ellipseTolerance,
          [key]: value,
        },
      };
    });
  } else if (tolerance.drawtype === "Rectangle") {
    Object.entries(tolerance.rectangleTolerance).forEach((obj) => {
      const [key, value] = obj;
      if (Array.isArray(value)) {
        showDutyPoints.forEach((show, i) => {
          value[i].show = show;
        });
      }
      result = {
        ...result,
        rectangleTolerance: {
          ...result.rectangleTolerance,
          [key]: value,
        },
      };
    });
  } else if (tolerance.drawtype === "Plus") {
    Object.entries(tolerance.plusTolerance).forEach((obj) => {
      const [key, value] = obj;
      if (value != null) {
        let data = {};
        Object.entries(value).forEach((item, index) => {
          const [key, value] = item;
          showDutyPoints.forEach((show, i) => {
            value[i].show = show;
          });
          data = {
            ...data,
            [key]: value,
          };
        });
        result = {
          ...result,
          plusTolerance: {
            ...result.plusTolerance,
            [key]: data,
          },
        };
      }
    });
  }
  return result;
};

export const checkWholeNumber = ({ value }) => {
  return isNaN(parseInt(value)) ? value : Math.round(value);
};

export const checkDecimalNumber = ({ value, decimalPosition = 2 }) => { 
  let factor = 1;
  for (let i = 0; i < decimalPosition; i++) factor = factor * 10;
  return isNaN(parseInt(value))
    ? value
    : (Math.round(value * factor) / factor).toFixed(decimalPosition);
};

export const modifysetval = (unitsValue:any)=>{
  const units=[];
  unitsValue.forEach((units)=>{
    units.push({
      value:units,
    });
  });
  return units;
};

export const getUnits = (units:any) => {
  const unitsval=units;
  console.log(unitsval);
  return unitsval;
};

export const modifyPumpFamilyList = (familyList: any) => {
  const familyItems = [];
  familyList.forEach((family) => {
    familyItems.push({
      label: family,
      value: family,
    });
  });
  familyItems.unshift({ label: 'All', value: 'All' })
  return familyItems;
};

export const modifyPumpSubFamilyList = (subFamilyList: any) => {
  const subFamilyItems = [];
  subFamilyList.forEach((subFamily) => {
    subFamilyItems.push({
      label: subFamily,
      value: subFamily,
    });
  });
  subFamilyItems.unshift({ label: 'All', value: 'All' })
  return subFamilyItems;
};

export const modifyImpellerList = (impellerTypeList: any) => {
  const impellerItems = [];
  impellerTypeList.forEach((impeller) => {
    impellerItems.push({
      label: impeller,
      value: impeller,
    });
  });
  impellerItems.unshift({ label: 'All', value: 'All' })
  return impellerItems;
};

export const modifyImpellerMatList = (impellerMaterialList: any) => {
  const impellerMatItems = [];
  impellerMaterialList.forEach((impellerMat) => {
    impellerMatItems.push({
      label: impellerMat,
      value: impellerMat,
    });
  });
  impellerMatItems.unshift({ label: 'All', value: 'All' })
  return impellerMatItems;
};
