import { FlowMeterState } from "../../types/flowMeter";
import {
  CREATE_FLOWMETER_ERROR,
  CREATE_FLOWMETER_PENDING,
  CREATE_FLOWMETER_SUCCESS,
  CLEAR_FLOWMETER_ERROR,
  SET_INITIAL_STATE,
  OPEN_CREATE_FLOWMETER_MODAL,
  CLOSE_CREATE_FLOWMETER_MODAL,
  SET_FLOWMETERS_PENDING,
  SET_FLOWMETERS_LIST,
  SET_FLOWMETER_DETAILS_INITIAL_STATE,
  SET_FLOWMETER_DETAILS,
  GET_FLOWMETER_DETAILS_PENDING,
  GET_FLOWMETER_DETAILS_SUCCESS,
  OPEN_EDIT_FLOWMETER_MODAL,
  CLOSE_EDIT_FLOWMETER_MODAL,
  UPDATE_FLOWMETER_PENDING,
  UPDATE_FLOWMETER_SUCCESS,
  UPDATE_FLOWMETER_ERROR,
  CLEAR_UPDATE_FLOWMETER_ERROR,
  SET_FLOWMETER_LIST_ASYNC,
} from "../constants/flowMeter.constants";

const initialState: FlowMeterState = {
  errorMessage: null,
  isPending: false,
  isCreateFlowMeterModalOpen: false,
  isGetFlowMeterPending: false,
  flowMeters: [],
  searchValueAsync: "",
  isGetFlowMeterDetailsPending: false,
  isEditFlowMeterModalOpen: false,
  updateFlowMeterErrorMessage: "",
  isUpdateFlowMeterPending: false,
  flowMeter: null,
};

export default (
  localState: FlowMeterState = initialState,
  action: Action
): FlowMeterState => {
  switch (action.type) {
    case SET_INITIAL_STATE: {
      return {
        ...initialState,
        isCreateFlowMeterModalOpen: true,
      };
    }
    case CREATE_FLOWMETER_SUCCESS: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case CREATE_FLOWMETER_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case CREATE_FLOWMETER_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case CLEAR_FLOWMETER_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case OPEN_CREATE_FLOWMETER_MODAL: {
      return {
        ...localState,
        isCreateFlowMeterModalOpen: true,
      };
    }
    case CLOSE_CREATE_FLOWMETER_MODAL: {
      return {
        ...localState,
        errorMessage: null,
        isCreateFlowMeterModalOpen: false,
      };
    }
    case SET_FLOWMETERS_PENDING: {
      return {
        ...localState,
        isGetFlowMeterPending: true,
      };
    }
    case SET_FLOWMETERS_LIST: {
      const { flowMetersOdata } = action.payload;
      return {
        ...localState,
        isGetFlowMeterPending: false,
        flowMeters: flowMetersOdata && flowMetersOdata.value,
      };
    }
    case SET_FLOWMETER_DETAILS_INITIAL_STATE: {
      return {
        ...initialState,
      };
    }
    case SET_FLOWMETER_DETAILS: {
      return {
        ...localState,
        flowMeter: action.payload,
      };
    }
    case GET_FLOWMETER_DETAILS_PENDING: {
      return {
        ...localState,
        isGetFlowMeterDetailsPending: true,
      };
    }
    case GET_FLOWMETER_DETAILS_SUCCESS: {
      return {
        ...localState,
        isGetFlowMeterDetailsPending: false,
      };
    }
    case OPEN_EDIT_FLOWMETER_MODAL: {
      return {
        ...localState,
        isEditFlowMeterModalOpen: true,
      };
    }
    case CLOSE_EDIT_FLOWMETER_MODAL: {
      return {
        ...localState,
        isEditFlowMeterModalOpen: false,
        updateFlowMeterErrorMessage: null,
      };
    }
    case UPDATE_FLOWMETER_PENDING: {
      return {
        ...localState,
        isUpdateFlowMeterPending: true,
      };
    }
    case UPDATE_FLOWMETER_SUCCESS: {
      return {
        ...localState,
        isUpdateFlowMeterPending: false,
      };
    }
    case UPDATE_FLOWMETER_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        updateFlowMeterErrorMessage: errorMessage,
        isUpdateFlowMeterPending: false,
      };
    }
    case CLEAR_UPDATE_FLOWMETER_ERROR: {
      return {
        ...localState,
        updateFlowMeterErrorMessage: null,
        isUpdateFlowMeterPending: false,
      };
    }
    case SET_FLOWMETER_LIST_ASYNC: {
      const { flowMeterListAsync } = action.payload;
      return {
        ...localState,
        flowMeterListAsync
      };
    }
    default: {
      return localState;
    }
  }
};
