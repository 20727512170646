import moment from "moment";
import agent from "../../services/agent";
import {
  clearEnableUserError,
  clearLoginUserError,
  enableUserError,
  enableUserPending,
  enableUserPendingError,
  enableUserPendingSuccess,
  loginUserError,
  loginUserPending,
  loginUserPendingError,
  loginUserPendingSuccess,
  loginUserSuccess,
} from "./login.actions";
import {
  SET_CURRENT_PROFILE,
  SET_FORGOT_PASSWORD_CONFIRMATION_STATUS,
} from "../constants/profile.constants";
import { ProfileState } from "../../types/profile";
import { clearForm } from "./form.actions";
import { showSnackbarStatus } from "./snackbar.actions";

export const setTokenDetails = (authData: any) => {
  const { accessToken, expiresInSec, tokenType } = authData;
  const expires = moment().unix() + expiresInSec;
  sessionStorage.setItem("token", accessToken);
  // set the expired time as (now + value from server)
  sessionStorage.setItem("expires_in", expires);
  sessionStorage.setItem("token_type", tokenType);
};

const setCurrentProfile = (currentProfile: ProfileState): Action => ({
  type: SET_CURRENT_PROFILE,
  payload: currentProfile,
});

export const setForgotPasswordConfirmationStatus = (status: string): Action => ({
  type: SET_FORGOT_PASSWORD_CONFIRMATION_STATUS,
  payload: status,
});

export const userLogin = (email: string, password: string) => (
  dispatch: Function
) => {
  dispatch(loginUserPending());
  dispatch(clearLoginUserError());
  agent.Auth.login(email, password)
    .then((authData) => {
      setTokenDetails(authData);
      const { accessToken, expiresInSec } = authData;
      agent.Auth.getCurrentUser(accessToken)
        .then((currentUser) => {
          dispatch(loginUserPendingSuccess());
          dispatch(setCurrentProfile(currentUser));
          dispatch(
            loginUserSuccess(currentUser.email, accessToken, expiresInSec)
          );
        })
        .catch((err) => {
          dispatch(loginUserPendingError());
          console.log("Get Current User server error", err);
        });
    })
    .catch((err) => {
      dispatch(loginUserPendingError());
      if (err && err.response) {
        const { error } = err.response.body;
        dispatch(loginUserError(error));
      } else {
        console.log("User login server error", err);
      }
    });
};

export const enableUserAccount = (code: string) => (
  dispatch: Function,
  getState: Function
) => {
  const currentState = getState();
  const { formCompleteRegistration } = currentState && currentState.form;

  dispatch(enableUserPending());
  dispatch(clearEnableUserError());
  agent.Auth.enableAccount(formCompleteRegistration, code)
    .then((result) => {
      dispatch(userLogin(result, formCompleteRegistration.password));
      dispatch(enableUserPendingSuccess());
      dispatch(clearForm());
    })
    .catch((err) => {
      dispatch(enableUserPendingError());
      if (err && err.response) {
        const { message } = err.response.body;
        dispatch(enableUserError(message));
        return;
      }
      console.log("enable user server error", err);
      dispatch(enableUserError(null));
      dispatch(clearForm());
      dispatch(
        showSnackbarStatus("Something went wrong. Please try again later")
      );
    });
};

export const forgotPassword = (email: string) => (dispatch: Function) => {
  dispatch(setForgotPasswordConfirmationStatus("INPROGRESS"));
  agent.Auth.forgotPassword(email)
    .then((result) => {
      dispatch(setForgotPasswordConfirmationStatus("EMAIL_SENT"));
      dispatch(clearForm());
    })
    .catch((err) => {
      dispatch(setForgotPasswordConfirmationStatus("INVALID_EMAIL"));
    });
};

export const resetPassword = (code: string) => (
  dispatch: Function,
  getState: Function
) => {
  const currentState = getState();
  const { formResetPassword } = currentState && currentState.form;

  dispatch(clearEnableUserError());
  dispatch(enableUserPending());
  agent.Auth.resetPassword(formResetPassword, code)
    .then((result) => {
      dispatch(userLogin(result, formResetPassword.password));
      dispatch(enableUserPendingSuccess());
      dispatch(clearForm());
    })
    .catch((err) => {
      dispatch(enableUserPendingError());
      if (err && err.response) {
        const { message } = err.response.body;
        dispatch(enableUserError(message));
        return;
      }
      console.log("reset password error", err);
      dispatch(enableUserError(null));
      dispatch(clearForm());
      dispatch(
        showSnackbarStatus("Something went wrong. Please try again later")
      );
    });
};
