import {
  CLEAR_EXPORT_DATA_ERROR,
  CLEAR_UPLOAD_CSV_DATA_ERROR,
  EXPORT_DATA_ERROR,
  EXPORT_DATA_PENDING,
  EXPORT_DATA_SUCCESS,
  SET_EXPORT_DATA_INITIAL_STATE,
  SET_UPLOAD_CSV_FILES,
  SET_UPLOAD_CSV_INITIAL_STATE,
  UPLOAD_CSV_DATA_ERROR,
  UPLOAD_CSV_DATA_PENDING,
  UPLOAD_CSV_DATA_SUCCESS,
} from "../constants/export.constants";

const initialState = {
  isPending: false,
  isFileUploadPending: false,
  errorMessage: null,
  fileNames: [],
  successMessage: "",
};

const exportReducer = (
  localState: Object = initialState,
  action: any
): Object => {
  switch (action.type) {
    case SET_EXPORT_DATA_INITIAL_STATE: {
      return {
        ...initialState,
      };
    }
    case EXPORT_DATA_SUCCESS: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case EXPORT_DATA_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case EXPORT_DATA_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case CLEAR_EXPORT_DATA_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case SET_UPLOAD_CSV_INITIAL_STATE: {
      return {
        ...initialState,
      };
    }
    case UPLOAD_CSV_DATA_SUCCESS: {
      return {
        ...localState,
        isFileUploadPending: false,
      };
    }
    case UPLOAD_CSV_DATA_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isFileUploadPending: false,
      };
    }
    case UPLOAD_CSV_DATA_PENDING: {
      return {
        ...localState,
        isFileUploadPending: true,
      };
    }
    case CLEAR_UPLOAD_CSV_DATA_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case SET_UPLOAD_CSV_FILES: {
      return {
        ...localState,
        fileNames: action.payload ?? [],
        successMessage: "File uploaded successfully",
      };
    }
    default:
      return localState;
  }
};

export default exportReducer;
