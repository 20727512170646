export const EXPORT_DATA_SUCCESS = "EXPORT_DATA_SUCCESS";
export const EXPORT_DATA_ERROR = "EXPORT_DATA_ERROR";
export const EXPORT_DATA_PENDING = "EXPORT_DATA_PENDING";
export const CLEAR_EXPORT_DATA_ERROR = "CLEAR_EXPORT_DATA_ERROR";
export const SET_EXPORT_DATA_INITIAL_STATE = "SET_EXPORT_DATA_INITIAL_STATE";
export const UPLOAD_CSV_DATA_SUCCESS = "UPLOAD_CSV_DATA_SUCCESS";
export const UPLOAD_CSV_DATA_ERROR = "UPLOAD_CSV_DATA_ERROR";
export const UPLOAD_CSV_DATA_PENDING = "UPLOAD_CSV_DATA_PENDING";
export const CLEAR_UPLOAD_CSV_DATA_ERROR = "CLEAR_UPLOAD_CSV_DATA_ERROR";
export const SET_UPLOAD_CSV_INITIAL_STATE = "SET_UPLOAD_CSV_INITIAL_STATE";
export const SET_UPLOAD_CSV_FILES = "SET_UPLOAD_CSV_FILES";
